export default function capitalize(string, exceptions = ["of", "a", "the", "as", "and"]) {
    let words = string.split(" ");
    let capitalized = "";

    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        if (!exceptions.includes(word) || i == 0) {
            capitalized += word.charAt(0).toUpperCase() + word.slice(1) + " ";
        } else {
            capitalized += word + " ";
        }
    }

    return capitalized.trim();
}
