import "../../css/pages/blog.scss";

import BasePage from "./base";
import LazyPagination from "../modules/lazyPagination";
import ListingSearch from "../modules/listingSearch";
import sectionNav from "../modules/sectionNav";
import tabsToggle from "../modules/tabsToggle";

class BlogPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    onReady() {
        super.onReady();

        tabsToggle.init();
        sectionNav.init();

        new LazyPagination({
            context: this.context || ""
        });

        new ListingSearch();
    }
}

new BlogPage();
