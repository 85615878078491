function init() {
    let togglers = document.getElementsByClassName("sectionSubnav__menuToggler");
    if (togglers && togglers.length > 0) {
        for (let i = 0; i < togglers.length; i++) {
            togglers[i].addEventListener("click", function(e) {
                e.preventDefault();
                let targetParent = togglers[i].parentElement.parentElement;
                if (targetParent.classList.contains("is-open")) {
                    targetParent.classList.remove("is-open");
                    togglers[i].classList.remove("is-open");
                } else {
                    targetParent.classList.add("is-open");
                    togglers[i].classList.add("is-open");
                }
            });
        }
    }

    let mainTogglers = document.getElementsByClassName("sectionSubnav__mobileWrap");
    if (mainTogglers && mainTogglers.length > 0) {
        for (let i = 0; i < mainTogglers.length; i++) {
            mainTogglers[i].addEventListener("click", function(e) {
                e.preventDefault();
                const parent = this.parentNode;
                const toggleIcon = this.querySelector(".sectionSubnav__menuToggler");
                if (parent.classList.contains("is-open")) {
                    parent.classList.remove("is-open");
                    toggleIcon.classList.remove("is-open");
                } else {
                    parent.classList.add("is-open");
                    toggleIcon.classList.add("is-open");
                }
            });
        }
    }

    const navSubLinks = document.querySelectorAll(".sectionSubnav__subNavLink--hasChildren");
    if (navSubLinks && navSubLinks.length > 0) {
        for (let i = 0; i < navSubLinks.length; i++) {
            navSubLinks[i].querySelector(".sectionSubnav__subNavLinkItem").addEventListener("click", e => {
                e.preventDefault();
            });
            navSubLinks[i].querySelector(".sectionSubnav__subNavLinkItemContainer").addEventListener("click", () => {
                if (navSubLinks[i].classList.contains("is-open")) {
                    navSubLinks[i].classList.remove("is-open");
                } else {
                    navSubLinks[i].classList.add("is-open");
                }
            });
        }
    }
}

export default { init };
