function init() {
    const ctaItem = document.querySelectorAll(".cta--sme li");
    const searchForm = document.querySelector(".tabsHeader__search--sme");
    const filter = document.querySelector(".tabs__selects--sme");

    if (ctaItem) {
        ctaItem.forEach(cta => {
            const authorName = cta.querySelector("a").getAttribute("title");
            const url = cta.querySelector("a").getAttribute("href");

            cta.addEventListener("click", () => {
                const data = {
                    event: "authorClick",
                    page: {
                        category: "SME Listing | Author CTA",
                        label: `Author | ${authorName}`,
                        path: url
                    }
                };
                window.dataLayer.push(data);
            });
        });
    }

    if (searchForm) {
        searchForm.addEventListener("submit", () => {
            const searchValue = searchForm.querySelector("#authorSearchInput").value;
            const data = {
                event: "authorSearch",
                page: {
                    category: "SME Listing | Site Search",
                    label: `Search | ${searchValue}`,
                    search: searchValue
                }
            };
            window.dataLayer.push(data);
        });
    }

    if (filter) {
        const industry = filter.querySelector(".tabs__select--industry");
        const capability = filter.querySelector(".tabs__select--capability");
        const language = filter.querySelector(".tabs__select--language");
        const region = filter.querySelector(".tabs__select--region");

        industry.addEventListener("change", e => {
            const filterValue = e.target.value;
            const data = {
                event: "authorFilter",
                page: {
                    category: "SME Listing | Filter Industry",
                    label: `Filter Industry | ${filterValue}`,
                    filter: filterValue
                }
            };
            window.dataLayer.push(data);
        });

        capability.addEventListener("change", e => {
            const filterValue = e.target.value;
            const data = {
                event: "authorFilter",
                page: {
                    category: "SME Listing | Filter Capability",
                    label: `Filter Capability | ${filterValue}`,
                    filter: filterValue
                }
            };
            window.dataLayer.push(data);
        });

        language.addEventListener("change", e => {
            const filterValue = e.target.value;
            const data = {
                event: "authorFilter",
                page: {
                    category: "SME Listing | Filter Language",
                    label: `Filter Language | ${filterValue}`,
                    filter: filterValue
                }
            };
            window.dataLayer.push(data);
        });

        region.addEventListener("change", e => {
            const filterValue = e.target.value;
            const data = {
                event: "authorFilter",
                page: {
                    category: "SME Listing | Filter Region",
                    label: `Filter Region | ${filterValue}`,
                    filter: filterValue
                }
            };
            window.dataLayer.push(data);
        });
    }
}

export default { init };
