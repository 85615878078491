import extend from "../modules/extend";
import axios from "axios";
import capitalize from "../modules/capitalize";
import smeListing from "../modules/smeListing";

export default class LazyPagination {
    constructor(ops) {
        extend(
            this,
            {
                list: "",
                page: 1,
                totalPages: 0,
                loading: false,
                moreResults: true,
                totalItems: 100,
                itemsPerPage: 15,
                context: "",
                bottomFactor: 1.3
            },
            ops
        );

        this.atMax = new Event("atMax");
        this.setElement();
    }

    setElement() {
        let self = this;
        this.list = document.getElementsByClassName("lazy-pagination")[0];

        if (this.list) {
            this.url = this.list.getAttribute("data-pagination-url");
            this.itemsPerPage = this.list.getAttribute("data-items-per-page");
            this.section = this.list.getAttribute("data-section");
            this.baseUrl = this.list.getAttribute("data-base-url");

            if (this.list.classList.contains("ctaList--lazyFilterable")) {
                this.page = 0;
                this.getFirstPage();

                this.list.addEventListener("filter", function() {
                    if (self.hasMorePages()) {
                        self.getNextPage();
                    }
                });
            }

            window.addEventListener("scroll", function() {
                self.onScroll();
            });
        }
    }

    isNearBottom() {
        let rect = this.list.getBoundingClientRect();
        let bottomPos = rect.top + rect.height;
        let windowHeight = window.innerHeight * this.bottomFactor;
        return bottomPos < windowHeight;
    }

    hasMorePages() {
        let self = this;
        let totalPages = Math.ceil(self.totalItems / self.itemsPerPage);

        if (!this.maxSent && this.page >= totalPages) {
            self.list.dispatchEvent(self.atMax);
            this.maxSent = true;
        }

        return this.page < totalPages;
    }

    onScroll() {
        if (this.isNearBottom() && !this.loading && this.hasMorePages()) {
            this.getNextPage();
        }
    }

    setLoading(status) {
        this.loading = status;
    }

    getCategoryJSON(categories) {
        return categories
            .filter(d => d) // remove null and undefined values
            .map(jsonString => {
                return jsonString ? JSON.parse(jsonString.replace(/'/g, '"')) : null;
            });
    }

    getCategoryListItems(categories, isAuthor = false) {
        let self = this;
        let jsonCats = this.getCategoryJSON(categories);

        if (isAuthor) {
            jsonCats = jsonCats.filter(d => d.path && (d.path.includes("industries") || d.path.includes("capabilities")));
        }

        let sortedCats = jsonCats.sort((a, b) => {
            if (a.displayName > b.displayName) {
                return 1;
            } else if (a.displayName < b.displayName) {
                return -1;
            } else {
                return 0;
            }
        });

        let categoryListItems = sortedCats.map(category => {
            return category ? `<li class="categoryItem"><a href="${self.baseUrl}?category=${category.name}">${category.displayName || capitalize(category.name.replace("-", " "))}</a></li>` : null;
        });

        return `
            <ul class="listItem__categoryList">
                ${categoryListItems.join(" ")}
            </ul>
        `;
    }

    limitChar(text, count = 170) {
        return text ? text.slice(0, count) + (text.length > count ? "..." : "") : "";
    }

    getMarkupForType(item) {
        let markup;

        switch (item.type) {
            case "blogPromo":
                markup = this.getPromoHtml(item, item.cutStyle);
                break;
            case "author":
                markup = this.getAuthorHtml(item);
                break;
            case "blogPost":
                markup = this.getBlogHtml(item);
                break;
            default:
                markup = this.getBlogHtml(item);
        }

        return markup;
    }

    getAuthorHtml(item) {
        let itemCategories = item.categories ? this.getCategoryJSON(item.categories, true).map(d => d.name) : [];
        let el = document.createElement("li");
        el.setAttribute("data-categories", itemCategories);

        if (!item.thumbnail) item.thumbnail = "#";

        if (item.description && item.description.includes("<p")) {
            item.description = item.description
                .replaceAll("<p>", "")
                .replaceAll(`<p class="authorDescription">`, "")
                .replaceAll("</p>", "");
        }

        el.innerHTML = `
            <div class="cta cta--vertical">
                <div class="cta__image">
                    <a href="${item.url}" title="${item.title}" target="_blank" class="cta__image">
                        <img class="image--thumb" src="${item.thumbnail}" alt="" />
                    </a>
                </div>
                <div class="cta__content">
                    <a href="${item.url}" title="${item.title}" target="_blank">
                        <h3 class="cta__title titleHr">${item.title}</h3>
                        <h4 class="cta__subtitle">${item.subtitle}</h4>
                        <p class="cta__description">${this.limitChar(item.description, 170)}</p>
                    </a>
                </div>
            </div>
        `;

        return el;
    }

    getPromoHtml(item, cutStyle = false) {
        let button,
            reducedDesc,
            promoStyle = cutStyle ? "video" : "content";

        if (item.description) {
            reducedDesc = this.limitChar(item.description, 170);
        }

        if (item.vidyardID) {
            button = `<div class="blog-promo-button vidyard__videoButton" data-uuid="${item.vidyardID}">
                ${item.buttonText}
            </div>`;
        } else {
            button = `<a href="${item.linkType}">${item.buttonText}</a>`;
        }

        let el = document.createElement("li");
        el.classList = `blog-promo-wrapper blog-border-${promoStyle}`;
        el.innerHTML = `
            <div class="cta cta--vertical blog-promo-container">
                <a href="${item.linkType}" title="${item.title}">
                    <div class="cta__image">
                        <img class="image--thumb blog-image-${promoStyle}" src="${item.thumbnail}" alt="">
                    </div>
                </a>
                <div class="cta__content blog-promo-${promoStyle}">
                    <a href="${item.linkType}" title="${item.title}">
                        <h3 class="cta__title blog-promo-title">${item.title || ""}</h3>
                        <p class="cta__description">${reducedDesc}</p>
                        <span class="blogListing__blogDate">${item.datePosted}</span>
                    </a>
                    ${button}
                </div>
            </div>`;
        return el;
    }

    getBlogHtml(item) {
        let reducedDesc;
        if (item.description) {
            reducedDesc = this.limitChar(item.description, 170);
        }

        let el = document.createElement("li");
        el.innerHTML = `
            <div class="cta cta--vertical">
                <a href="${item.url}">
                    <div class="cta__image">
                        <img class="image--thumb" src="${item.thumbnail}" alt="">
                    </div> 
                </a>
                <div class="cta__content">
                    <a href="${item.url}">
                        <h3 class="cta__title titleHr">${item.title || ""}</h3>
                        <p class="cta__description">${reducedDesc}</p>
                        <span class="blogListing__blogDate">${item.datePosted}</span>
                    </a>
                    ${this.getCategoryListItems(item.categories || [])}
                </div>
            </div>`;
        return el;
    }

    makeResults(data) {
        let self = this;
        if (data.data.length) {
            if (this.section) {
                data.data.forEach(item => {
                    self.makeNewsRow(item);
                });
            } else {
                data.data.forEach(item => {
                    if (!item.hide) {
                        let resultItem = self.getMarkupForType(item);
                        self.list.appendChild(resultItem);
                    }
                });
            }
        }
        smeListing.init();
    }

    makeNewsRow(item = []) {
        if (item) {
            let elem = document.createElement("div");
            elem.classList.add("newsListing__listRow");
            elem.innerHTML = `<div class="newsListing__article">
                    <a href="${item.url || "#"}" class="newsListing__articleLink">
                        <h5 class="newsListing__articleTitle">${item.title || ""}</h5>
                    </a>
                    <div class="newsListing__articleDate">${item.datePosted || ""}</div>
                </div>`;
            this.list.appendChild(elem);
        }
    }

    getFirstPage() {
        if (this.hasMorePages()) {
            this.getNextPage();
        } else {
            this.maxSent = true;
        }
    }

    getNextPage() {
        let self = this;
        this.loading = true;
        this.page = this.page + 1;

        axios
            .get(self.url, {
                params: {
                    page: self.page,
                    rows: self.itemsPerPage
                }
            })
            .then(function(response) {
                if (response.data && response.data.data) {
                    self.itemsPerPage = response.data.rowsPerPage || 1;
                    self.totalItems = response.data.numFound || 1;
                    self.makeResults(response.data);
                    self.loading = false;
                }
            })
            .catch(function() {
                self.loading = false;
            });
    }
}
