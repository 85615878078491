export default class ListingSearch {
    constructor() {
        this.init();
    }

    init() {
        let self = this;
        document.querySelectorAll(".tabsHeader__searchField").forEach(function(el) {
            self.bindSearchEvents(el);
        });
    }

    getSearchEl(el) {
        return el.closest(".tabsHeader__search");
    }

    bindSearchEvents(el) {
        let self = this;

        el.addEventListener("focus", function() {
            self.getSearchEl(el).classList.add("tabsHeader--searchActive");
        });

        el.addEventListener("blur", function() {
            self.getSearchEl(el).classList.remove("tabsHeader--searchActive");
        });
    }
}
