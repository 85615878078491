function toggleTabs(parent) {
    let togglers = parent.querySelectorAll(".tabs__toggler");
    if (togglers.length) {
        togglers.forEach(element => {
            element.addEventListener("click", () => {
                let tabs = parent.querySelector(".tabs--filterTabs");
                tabs.classList.toggle("active");
            });
        });
    }
}

function init(parent = document) {
    toggleTabs(parent);
}

export default { init };
